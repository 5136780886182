.stats {
    padding-bottom: 1em;
    text-align: center;
}

.stats-table {
    margin: 1em auto;
    padding: 1em;
    width: 100%;
    background-color: var(--bg-light);
    border-radius: 1em;
    font-size: 1.25em;
    max-width: 35em;
}

.stats-row {
    display: flex;
    justify-content: space-between;
}

.table-text {
    text-align: left;
}

#too-hot {
    color: var(--hot);
}

#too-cold {
    color: var(--cold);
}

#so-close {
    color: var(--so-close);
}

#perfect-guesses {
    color: var(--correct);
}

.reset-stats,
.confirm-delete {
    background-color: var(--delete);
}

.confirm-btns {
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.confirm-cancel {
    background: linear-gradient(to bottom left, var(--clear-sky-light) 25%, var(--clear-sky-dark));
}