:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    font-weight: 400;

    --off-white: #f5f5f5;
    --bg: #232832;
    --dark-light: #15171b;
    --dark: #0f1118;
    --bg-light: #2f3642;
    --bg-text: #b3c2d0;

    /* Themes */
    --clear-sky-light: #0090a3;
    --clear-sky-dark: #184883;
    --dark-sky-light: #111a36;
    --dark-sky-dark: #030916;
    --sunset-light: #fcde17;
    --sunset-dark: #eb621f;
    --cloudy-sky-light: #a7a8ad;
    --cloudy-sky-dark: #4c5257;
    --snowy-sky-light: #e8f0f9;
    --snowy-sky-dark: #579ad0;

    --input-color: #a5bddd;
    --btn-bg: #003270;
    --btn-bg-hover: #062c5a;
    --spinner: #3498db;
    --link: #92f1fd;
    --line-color: #74a9b1;
    --hot: #ff8411;
    --cold: #90dfff;
    --so-close: #ffff00;
    --correct: #00e700;
    --delete: #be1313;
}

/* inter-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/inter-v13-latin-regular.woff2') format('woff2');
}
/* inter-500 - latin */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/inter-v13-latin-500.woff2') format('woff2');
}
/* inter-600 - latin */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/inter-v13-latin-600.woff2') format('woff2');
}
/* inter-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/inter-v13-latin-700.woff2') format('woff2');
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: var(--off-white);
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--dark);
}

a,
select,
.setting-input,
.arrow-back,
.search-result,
.favorite-entry {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    all: unset;
    cursor: pointer;
    font-weight: 500;
}

    button:not(.delete-favorite, .close, .edit-btn, .search-cancel) {
        padding: 0.5em 0.75em;
        border-radius: 2em;
        font-size: 1.2em;
    }

hr {
    background-color: var(--line-color);
    border: none;
    height: 1px;
    margin: 1em auto;
    border-radius: 1em;
}

img {
    user-select: none;
    margin: 0 auto;
    display: block;
}

h1 {
    font-size: 2em;
}

h1,
h2 {
    font-weight: 600;
}

ul {
    list-style-type: none;
}

input {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    background-color: var(--bg-light);
    border: none;
}

select {
    background-color: transparent;
    border: none;
    font-size: 1.1em;
}

option {
    background-color: var(--bg-light);
}

::placeholder {
    color: var(--input-color);
    opacity: 1;
}

.arrow-menu {
    fill: var(--off-white);
    transform: rotate(180deg) scale(1.25);
}

.first-arrow {
    transform: rotate(180deg) scale(1.5);
}

.arrow-back {
    fill: var(--link);
    transform: scale(2);
}

.loading {
    margin: 0 auto;
    margin-top: 10em;
    border: 14px solid var(--bg-dark);
    border-radius: 50%;
    border-top: 5px solid var(--spinner);
    width: 6em;
    height: 6em;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
  
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Tablet - 700px */
@media only screen and (min-width: 43.75em) {
    h1 {
        font-size: 2.5em;
    }
}

/* Small Desktop - 1024px */
@media only screen and (min-width: 64em) {
    h1 {
        font-size: 3em;
    }
}