.weather-game {
    padding-bottom: 1em;
    text-align: center;
}

    .weather-game button {
        background: linear-gradient(to bottom left, var(--clear-sky-light) 25%, var(--clear-sky-dark));
    }

.game input {
    margin: 1em auto;
    color: var(--off-white);
    width: 3em;
    font-size: 1.25em;
    background-color: transparent;
    border-bottom: 2px solid var(--off-white);
}

.game form {
    margin: 1em auto;
    font-weight: 500;
}

.temperature-guess,
.guess-input span {
    font-size: 1.3em;
}

.hot {
    color: var(--hot);
}

.cold {
    color: var(--cold);
}

.so-close {
    color: var(--so-close);
    font-style: italic;
}

.correct {
    color: var(--correct);
}