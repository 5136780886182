.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(to bottom left, var(--dark-light) 25%, var(--dark));
}

.app-container {
    flex: 1;
}

.top-nav {
    margin: 0 auto;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-menu-container {
    margin: 0 1.2em;
}

.main-menu {
    margin: 1.2em auto;
    padding: 1em 1.25em;
    background: linear-gradient(to bottom left, var(--clear-sky-light) 25%, var(--clear-sky-dark));
    text-align: center;
    border-radius: 2em;
    max-width: 50em;
}

    .main-menu h1 {
        margin: 0.5em auto 0.75em auto;
    }

    .main-menu img {
        height: 5em;
    }

#nav-tabs {
    margin: 0 auto;
    width: max-content;
}

    #nav-tabs ul {
        margin: 1em auto;
        display: flex;
        flex-direction: column;
        gap: 2em;
    }

        #nav-tabs ul li {
            font-size: 1.25em;
            font-weight: 500;
            cursor: pointer;
        }

            #nav-tabs ul li:first-child {
                font-size: 2em;
            }

            #nav-tabs ul li span {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1em;
            }

footer {
    padding: 1em 1.2em 1em;
    text-align: center;
}

.donation-btn {
    margin: 0 auto 2em;
    display: block;
    width: 217px;
}

    .donation-btn img {
        height: 60px;
        width: 217px;
    }

.api-link a {
    color: var(--link);
    width: max-content;
}

    .api-link a:hover {
        text-decoration: underline;
    }