.weather-card {
    margin: 1em auto;
    padding: 2em 0;
    border-radius: 2em;
    background: linear-gradient(to bottom left, var(--clear-sky-light) 25%, var(--clear-sky-dark));
    max-width: 35em;
}

    .weather-card h1 {
        margin-bottom: 0.25em;
        padding: 0 0.25em;
        font-size: 1.5em;
    }

.card-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.weather-card img {
    margin: 0;
    height: 7em;
}

.temp-info {
    max-width: 8em;
}

.temp-unit {
    font-size: 0.7em;
}

.weather-card h2 {
    font-size: 2.25em;
    font-weight: 500;
}

.extra-info-div {
    padding-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.extra-info {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}

.high-low {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}