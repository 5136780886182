.game-result {
    margin: 1em auto;
}

    .game-result h2 {
        margin: 0.5em auto;
        font-size: 2em;
    }

    .game-result h3 {
        margin-bottom: 1em;
        font-size: 1.5em;
    }