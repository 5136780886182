.settings {
    margin: 0 auto;
    padding-bottom: 1em;
    max-width: 35em;
}

    .settings h2 {
        padding: 1em 0;
    }

    .settings form {
        padding: 1em;
        background-color: var(--bg-light);
        border-radius: 1em;
    }

        .settings form label {
            font-size: 1.25em;
        }

.form-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.setting-switch {
    border: 2px solid var(--line-color);
    border-radius: 10px;
    width: max-content;
}

    .setting-switch label {
        margin: 0.1em;
        padding: 0.25em 0.5em;
        font-size: 1em !important;
        display: inline-block;
        background-color: inherit;
        border-radius: 7px;
        cursor: pointer;
    }

    .setting-switch input[type="radio"] {
        display: none;
    }

        .setting-switch input[type="radio"]:checked + label {
            background-color: var(--line-color);
        }